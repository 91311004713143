import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import chapterAPI from "../services/chapterAPI";
import repertoriesAPI from "../services/repertoryAPI";
import {SET_SELECTED_CHAPTER, SET_SELECTED_REPERTORY} from "./tabs";
import {SET_RESULTS} from "./search";

export const GET_CHAPTERS = createAsyncThunk(
    "@@REPERTORIES/GET_CHAPTERS",
    async (params, thunkAPI) => {
        
        const response = await chapterAPI.getChapters(params);
        const firstChapter = params.selectedChapter ? {id: params.selectedChapter} : response.data[0];
        if (firstChapter) {
            
            thunkAPI.dispatch(
                SET_SELECTED_CHAPTER({
                    id: params.documentId,
                    chapter: firstChapter.id,
                })
            );
        }
        return {...params, ...response};
    }
);

export const GET_REPERTORIES = createAsyncThunk(
    "@@REPERTORIES/GET_REPERTORIES",
    async (params, thunkAPI) => {
        const state = thunkAPI.getState();

        
        const response = await repertoriesAPI.getRepertories(params);
        const prevReps = state?.search?.repertories?.data;
        const data = prevReps && params.page != 1 ? JSON.parse(JSON.stringify(prevReps)) : {};
    
        (response?.data || []).forEach((r) => {
            // Get all the data that you already have but that would be overwritten by Ajax call
            r.chapters = state?.repertories[r.id] && state?.repertories[r.id].chapters;
            data[r.id] = r
        });
        thunkAPI.dispatch(
            SET_RESULTS({
                key: "repertories",
                data: {...response, data},
            })
        );
        return {...params, ...response};
    }
);

export const GET_REPERTORY = createAsyncThunk(
    "@@REPERTORIES/GET_REPERTORY",
    async (params, thunkAPI) => {
        
        const response = await repertoriesAPI.getRepertory(params);
        const data = response.data;

        thunkAPI.dispatch(
            GET_CHAPTERS({documentId: params?.documentId, selectedChapter: params?.chapterId})
        );
        
        thunkAPI.dispatch(
            SET_SELECTED_REPERTORY(data)
        );
        
        return {...params, ...data};
    }
);


const repertoriesSlice = createSlice({
    name: "@@REPERTORIES",
    initialState: {},
    reducers: {},
    extraReducers: {
        [GET_REPERTORY.fulfilled]: (state, action) => {
            const data = action.payload;
            const addThisRepertories = {};
            addThisRepertories[data.id] = data;
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    repertories: false,
                },
                ...addThisRepertories,
            };
        },
        [GET_REPERTORIES.pending]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                repertories: true,
            },
        }),
        [GET_REPERTORIES.fulfilled]: (state, action) => {
            const {data} = action.payload;
            const addThisRepertories = {};
            (data || []).forEach((r) => (addThisRepertories[r.id] = r));
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    repertories: false,
                },
                ...addThisRepertories,
            };
        },
        [GET_REPERTORIES.rejected]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                repertories: false,
            },
        }),
        
        [GET_CHAPTERS.pending]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                chapters: true,
            },
        }),
        [GET_CHAPTERS.fulfilled]: (state, action) => {
            const {documentId, data} = action.payload;
            let chapters = data;
            
            if (!Array.isArray(chapters)) {
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        chapters: false,
                    },
                };
            }
            ;
            
            const addThisChapters = {};
            (chapters || []).forEach((c) => (addThisChapters[c.id] = c));
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    chapters: false,
                },
                [documentId]: {
                    ...state?.[documentId],
                    chapters: addThisChapters,
                },
            };
        },
        [GET_CHAPTERS.rejected]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                chapters: false,
            },
        })
    },
});

export default repertoriesSlice.reducer;
