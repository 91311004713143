import React from "react";
import {List} from "@mui/material";
import {matchPath} from "react-router-dom";
import NavItem from "./NavItem";
import {useTranslation} from "react-i18next";

const renderNavItems = ({ items, pathname, depth = 0 }) => {
    
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth }),
                []
            )}
        </List>
    );
};

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
    const {t} = useTranslation();

    const title = t(item.title)

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });
        acc.push(
            <NavItem
                depth={depth}
                id={item.id}
                icon={item.icon}
                info={item.info}
                key={item.id}
                open={Boolean(open)}
                title={title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                id={item.id}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={item.id + depth}
                title={title}
            />
        );
    }
    
    return acc;
};

export default renderNavItems;
