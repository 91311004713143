import {useEffect, useState} from "react";

export const getArrayResources = (obj) => {
    return Object.keys(obj).map((k) => obj[k]);
};

export const truncString = (string, length = 15) => {
    if (string) {
        return string.length > length ?
            string.substring(0, length) + "..." :
            string;
    }
}

export const getDefaultLang = (langs) => {
    return langs
        .filter(lang => lang.default === 'true')
        .map(lang => lang.lang)[0]
}


export const getRubricTextWithPath = (rubric, langAbbr) => {

    const texts = rubric?.parents
        .map(item => item?.texts?.[langAbbr]?.text);
    texts.push(rubric?.texts?.[langAbbr]?.text)
    return texts.join(' - ')

}

export const changeUrl = (url) => {
    window.history.pushState('', '', url);
}

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value]
    );

    return debouncedValue;
}

export const setSettings = (field, variable) => {
    let settings = getSettings() || {};
    settings[field] = variable;
    localStorage.setItem('settings', JSON.stringify(settings));
}

export const getSettings = () => {
    try {
        return JSON.parse(localStorage.getItem('settings'))
    } catch (e) {
        return null
    }
}

export const getDate = (input, specific = false) => {
    let date = new Date(input)
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        .toISOString()

    const splitDate = date.split('T');
    const yMD = splitDate[0]

    const hMS = splitDate[1].split('.')[0]

    return specific ? `${yMD} ${hMS}` : yMD
}

export const findPath = (tmp, rubrics) => {
    const result = {};
    result[tmp.level] = tmp.id;
    return recFindPath(tmp, tmp.level, rubrics, result);
}

export const getLookupText = (label, lang) => {
    if ( !label ) return;
    return label.userDescription || label.texts?.find(text => text.lang?.abbr === lang)?.text
}


const recFindPath = (tmp, level, rubrics, result) => {
    const prevRub = rubrics.filter(rubric => rubric.id === tmp.id - 1)[0]
    if (!prevRub || tmp.level == 2) {
        return result;
    }
    if (prevRub.level < tmp.level && !result[prevRub.level] && tmp.level <= level) {
        result[prevRub.level] = prevRub.id;
    }
    return recFindPath(prevRub, level, rubrics, result);
}

export const getTranslatedText = (texts, lang) => {
    return texts.filter(t => t.lang?.abbr == lang)?.[0]?.text || texts[0]?.text
}

export const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};


export const calcHoverPosition = (ref, monitor) => {
    const hoverBoundingRect = ref.current?.getBoundingClientRect()
    const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    return {hoverMiddleY, hoverClientY}
}
