import React, {useEffect, useState} from 'react';
import useAuth from "./useAuth";

const useFormatDate = (date) =>  {
    const [formattedDate, setFormattedDate] = useState(new Date());
    const {timezone, dateLocale} = useAuth();

    useEffect(() => {

        if ( date instanceof Date ) { //TODO: to remove
            date && setFormattedDate( new Date(date).toLocaleString(dateLocale, {timeZone: timezone}))
        }

        return () => {

        };
    });
    
    return formattedDate.toString();
};

export default useFormatDate;
