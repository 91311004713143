import React from 'react';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Feed from '@mui/icons-material/Feed';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import {Clipboard as ClipboardIcon} from "react-feather";
import YardIcon from '@mui/icons-material/Yard';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const icons = {
    LibraryBooks: LibraryBooksOutlinedIcon,
    BusinessCenter: BusinessCenterOutlinedIcon,
    PatientCircle: AccountCircleOutlinedIcon,
    Consultation: Feed,
    Map: MapOutlinedIcon,
    Translate: TranslateOutlinedIcon,
    Search: SearchOutlinedIcon,
    Clipboard: ClipboardIcon,
    Remedy: YardIcon,
    Author: LocalLibraryIcon
}

const Icon = ({name, style}) => {
    return React.createElement(icons[name], {style: style})
    
};

export default Icon;
