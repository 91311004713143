import {IconButton, Tooltip} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import {defaultTheme} from "../../theme";
import React from "react";
import useAuth from "../../hooks/useAuth";
import useConstants from "../../hooks/useConstants";

export default ({onClick}) => {
    const {roles} = useAuth();
    const {getApp} = useConstants();

    const app = getApp();

    const showTranslation = ( roles?.['TRA'] || roles?.['ADM'] );

    return (
        <>
            {showTranslation &&
            <Tooltip title={'Translations'} placement={'right'} arrow={true}>

                <IconButton
                    onClick={onClick}
                >
                    <TranslateIcon style={{color: defaultTheme.translationColor}}/>
                </IconButton>
            </Tooltip>
            }
        </>
    )
}
