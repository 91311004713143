import {   serviceInterface } from "./config";

const sendQuery = (body) => {
    return serviceInterface.post(`/rubric/mention/query`, body);
};

const sendAdvancedQuery = (body, params) => {
    return serviceInterface.post(`/rubric/advanced/query`, body, {params});
};
const sendMentionQuery = (body, params) => {
    return serviceInterface.post(`/rubric/mention/query`, body, {params});
};

const searchRubrics = (requiredParams, params) => {
    return serviceInterface.get(`/query/${requiredParams.searchId}/documents/${requiredParams.documentId}/rubrics`, {params});
}

const searchDocuments =  (requiredParams, params)  => {
    return serviceInterface.get(`/query/${requiredParams.searchId}/documents`, {params});
}

const search = (params={}) => {
    return serviceInterface.get(`/word/search`, {
        params
    });
};


export default {
    search,
    sendQuery,
    searchDocuments,
    searchRubrics,
    sendAdvancedQuery,
    sendMentionQuery
};
