export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "Repertories",
                patients: "Patients",
                materiaMedica: 'Materia medica',
                remedies: 'Remedies',
                authors: 'Authors'
            }
        },
        rubricOptions: {
            take: "Take",
            view: "View",
            saveAsBookmark: "Save as bookmark",
            addRemedy: "Add Remedy"
        },
        repertory: {
            settings: "Repertory settings",
            languages: 'Additional languages',
            mainLanguage: 'Main language'
        },
        search: "Search",
        settings: "Settings",
        lang: "Language",
        save: "save",
        selectRemedy: "Select remedy",
        languages: 'languages',
        description: "Description",
        close: "Close",
        note: "Notes"
    }
}