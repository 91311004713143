import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import rubricAPI from "../services/rubricAPI";
import {findPath} from "../utils";

export const GET_RUBRICS = createAsyncThunk(
    "@@REPERTORIES/GET_RUBRICS",
    async (params) => {
        const response = await rubricAPI.slide(params);
        return {...params, ...response};
    }
);

const repertoriesSlice = createSlice({
    name: "@@REPERTORIES",
    initialState: {
        position: {}
    },
    reducers: {
        CLEAR_RUBRICS: (state, action) => {
            const {documentId, chapterId} = action.payload;
            return {
                ...state,
                [`${documentId}-${chapterId}`]: null,
                position: {
                    ...state.position,
                    [`${documentId}-${chapterId}`]: 5
                }
            };
        },
        SAVE_POSITION: (state, action) => {
            const {documentId, chapterId, position} = action.payload;
            return {
                ...state,
                position: {
                    ...state.position,
                    [`${documentId}-${chapterId}`]: position,
                }
            };
        }
    },
    extraReducers: {
        [GET_RUBRICS.pending]: (state) => ({
            ...state,
            isLoading: true,
        }),
        [GET_RUBRICS.fulfilled]: (state, action) => {
            const {documentId, chapterId, data, direction, reload} = action.payload;
            
            const newState = JSON.parse(JSON.stringify(state));
            let rubrics = newState?.[`${documentId}-${chapterId}`] || [];
            
            if (data.length === 0) {
                
                if (direction === 'next' ) {
                    if (rubrics[rubrics.length - 1]) {
                        rubrics[rubrics.length - 1].isLast = true;
                    }
                } else {
                    if (rubrics[0]) {
                        rubrics[0].isFirst = true;
                    }
                }
                
                return {
                    ...state,
                    reload,
                    isLoading: false,
                    [`${documentId}-${chapterId}`]: rubrics
                };
            }
            
            if (direction === 'next') {
                data.map(item => rubrics.push(item))
            } else {
                data.map(item => rubrics.unshift(item))
            }
            

            return {
                ...state,
                reload,
                isLoading: false,
                [`${documentId}-${chapterId}`]: rubrics
            };
        },
        [GET_RUBRICS.rejected]: (state) => ({
            ...state,
            isLoading: false,
        }),
        
    },
});

export const {
    CLEAR_RUBRICS,
    SAVE_POSITION
} = repertoriesSlice.actions;

export default repertoriesSlice.reducer;
