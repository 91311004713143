import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './styles/main.scss';
import routes, {renderRoutes} from './routes/index';
import {Provider} from 'react-redux';
import store from './store';
import './i18n';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend'
import AppContextProvider from "./contexts/AppContextProvider";
import {SnackbarProvider} from "notistack";
import VersionControl from './VersionControl'
import LocalizationContext from "./contexts/LocalizationContext";
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})

const App = () => {

    return (
        <AppContextProvider>
            <LocalizationContext>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>
                        <DndProvider backend={HTML5Backend}>
                            <VersionControl/>
                            <Provider store={store}>
                                <Router>
                                    {renderRoutes(routes)}
                                </Router>
                            </Provider>
                        </DndProvider>
                    </SnackbarProvider>
                </QueryClientProvider>
            </LocalizationContext>
        </AppContextProvider>
    );
};

export default App;
