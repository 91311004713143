import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "@@APP",
  initialState: { isLoadingData: false },
  reducers: {
    LOAD_DATA: (state) => ({ ...state, isLoadingData: true }),
    END_LOAD_DATA: (state) => ({ ...state, isLoadingData: false }),
  },
});

export const { LOAD_DATA, END_LOAD_DATA } = appSlice.actions;

export default appSlice.reducer;
