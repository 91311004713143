import React from 'react';
import { AuthProvider } from './AuthContext';
import { TranslationProvider } from './TranslationContext';
import {ConstantsProvider} from "./ConstantsContext";
import {ClipboardProvider} from "./ClipboardContext";
import {SettingsProvider} from "./SettingsContext";
import {RepertoryProvider} from "./RepertoryContext";


const combineComponents = (...components) => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({ children }) => <>{children}</>,
    );
};

const providers = [
    AuthProvider,
    TranslationProvider,
    ConstantsProvider,
    SettingsProvider,
    ClipboardProvider,
    RepertoryProvider
]

const AppContextProvider = combineComponents(...providers);

export default AppContextProvider;
