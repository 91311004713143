import {createSlice} from "@reduxjs/toolkit";
import {getSettings, setSettings} from "../utils";


const config = createSlice({
    name: "@@CONFIG",
    initialState: {
        searchCollapsed: getSettings()?.searchCollapsed,
        translationCollapsed: getSettings()?.translationCollapsed || true,
        navWindOpen: false
    },
    reducers: {
        SET_COLLAPSE: (state, action) => {
            const {collapsed} = action.payload;
            setSettings('searchCollapsed', !collapsed);
            return {
                ...state,
                searchCollapsed: !collapsed
            };
        },
        SET_NAVIGATION_WINDOW: (state, action) => {
            const {open} = action.payload;
            return {
                ...state,
                navWindOpen: open
            };
        }
    },
});

export const {
    SET_COLLAPSE,
    SET_NAVIGATION_WINDOW
} = config.actions;

export default config.reducer;
