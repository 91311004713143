
import {apis, serviceInterface} from "./config";

const create = () => {
    return serviceInterface.post('/api-key',);
};


const search = (params) => {
    return serviceInterface.get('/api-key', {
        params
    });
};

export default {
    create,
    search
}
