import Axios from "axios";
import {API, LOGIN} from "../config";

const logout = async () => {
    await Axios.get(
        `${API.auth}${API.path}/auth/logout`,
        {withCredentials: true});
    window.location.href = `${LOGIN.url}`
}

const refreshToken = async () => {
    await Axios.get(
        `${API.auth}${API.path}/auth/refresh-token`,
        {withCredentials: true});
    return Promise.resolve();
}

export default {
    logout,
    refreshToken
};