export const appConfig = {
    //id: "CC",
    name: 'OpusPlus'
};

export const API = {
    url: process.env.REACT_APP_API_URL,
    auth: process.env.REACT_APP_AUTH_URL,
    path: '/api'
};

export const LOGIN = {
    url: process.env.REACT_APP_LOGIN_URL
};