import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
    Box, Grid,
    LinearProgress,
} from '@mui/material'

export default () => {

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Grid container height={'100%'}>
      <Box sx={{
          marginTop: '30%',
          marginLeft:'40%'
      }} width={400}>
        <LinearProgress />
      </Box>
    </Grid>
  );
};

