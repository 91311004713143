import {createSlice} from "@reduxjs/toolkit";

const repSettings = createSlice({
    name: "@@REPSETTINGS",
    initialState: {
        remedyOrder: 'abbr-asc',
        showRemNum: true,
        shownDegrees: {1: true, 2: true, 3: true, 4: true},
        langs: [],
        symPages: {},
        selectedTags: []
    },
    reducers: {
        SET_REMEDY_ORDER: (state, action) => {
            const {remedyOrder} = action.payload;
            return {
                ...state,
                remedyOrder
            }
        },
        SET_REP_SETTINGS: (state, action) => {
            const { showRemNum, shownDegrees, showReferences} = action.payload;
            return {
                ...state,
                showRemNum: showRemNum != null ? showRemNum : state.showRemNum,
                showReferences: showReferences != null ? showReferences : state.showReferences,
                shownDegrees: shownDegrees != null ? shownDegrees : state.shownDegrees
            };
        },
        SET_SELECTED_TAGS: (state, action) => {
            const {selected} = action.payload;
            return {
                ...state,
                selectedTags: selected
            };
        }
    },
});

export const {
    SET_SELECTED_TAGS,
    SET_REP_SETTINGS,
    SET_REMEDY_ORDER
} = repSettings.actions;

export default repSettings.reducer;
