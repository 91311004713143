import React, {useState} from "react";
import {
    Dialog,
    DialogContent, Box, Tabs, Tab,
} from "@mui/material";

import MyDialogTitle from "../../../DialogTitle";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import ApiKeys from "./ApiKeys";
import useSettings from "../../../../hooks/useSettings";
import PatientFields from "./PatientFields";
export default ({open, handleOpen}) => {

    const { tab = '1', handleTab } = useSettings();
    const handleChange = (event, newValue) => {
        handleTab(newValue);
    };

    return (
        <>

            {open &&
                <Dialog
                    open={open}
                    fullWidth
                    maxWidth={'lg'}
                    keepMounted
                    onClose={handleOpen}
                >
                    <MyDialogTitle handleOpen={handleOpen}>
                        Advanced Settings
                    </MyDialogTitle>
                    <DialogContent>
                        <TabContext value={tab}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Patient Fields" value="1"/>
                                    <Tab label="Api keys" value="2"/>
                                </TabList>
                            </Box>
                            <TabPanel value="1"><PatientFields/></TabPanel>
                            <TabPanel value="2"><ApiKeys/></TabPanel>
                        </TabContext>
                    </DialogContent>
                </Dialog>}
        </>
    )
}

