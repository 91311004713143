import React, {useState} from "react";
import {Card, CardContent, Grid, IconButton, TextField, Typography} from "@mui/material";
import fileAPI from "../../../../../services/fileAPI";
import {Check, Delete, Edit, FileDownload} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import Loader from "../../../../Loader";

export default ({file, index, addValue, handleRemove}) => {
    const {enqueueSnackbar} = useSnackbar();
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEdit = () => setEdit(!edit);

    const handleDelete = async (id) => {
        setLoading(true)
        handleRemove && await handleRemove(id)
        setLoading(false);
    }

    const handleUpdate = async () => {
        setLoading(true)
        try {
            await fileAPI.update(file)
            handleEdit()
            enqueueSnackbar(`File updated`, {
                variant: 'success'
            });
        } catch {
            enqueueSnackbar(`File could not be updated`, {
                variant: 'error'
            });
        }
        setLoading(false)

    }

    return (
        <Card>
            <CardContent>
                {!loading ? <>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            <img
                                src={file.extension != 'pdf' ? file.url : '/images/pdf.png'}
                                style={{maxWidth: '100%', height: '70px'}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                edit ? <TextField
                                        onKeyDown={(e) => e.key === "Enter" && handleUpdate()}
                                        label='Name'
                                        fullWidth
                                        defaultValue={file.description}
                                        onChange={(e) => addValue && addValue(e, index, 'description')}
                                        variant='outlined'
                                    /> :
                                    <Typography sx={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        fontSize: ".9rem",
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {`${file.description}.${file.extension}`}
                                    </Typography>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {edit ?
                                <IconButton onClick={handleUpdate}>
                                    <Check/>
                                </IconButton>
                                :

                                <IconButton
                                    onClick={handleEdit}
                                >
                                    <Edit/>
                                </IconButton>}
                        </Grid>

                        <Grid item xs={4}>
                            <IconButton
                                onClick={() => fileAPI.downloadFile(file.id)}
                            >
                                <FileDownload/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton edge='end' onClick={() => handleDelete(file.id)}>
                                <Delete/>
                            </IconButton>
                        </Grid>
                    </Grid>

                </> : <Loader/>}
            </CardContent>
        </Card>

    )
}