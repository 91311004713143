import {serviceInterface, getDefParams} from "./config";

const addPatientImage = (patientId, body) => {
    return serviceInterface.post(`/file/patient/${patientId}/image`, body, {
        params: {...getDefParams()}
    });
};

const get = (id) => {
    return serviceInterface.get(`/file/${id}`);

}

const downloadFile = async (id) => {
    const result = await get(id);
    window.open(
        result.data.url,
        '_blank'
    )
}
const getConsultationFiles = (id) => {
    return serviceInterface.get(`/file/consultation/${id}`)
}

const getNoteFiles = (id) => {
    return serviceInterface.get(`/file/note/${id}`)
}

const update = (body) => {
    return serviceInterface.put(`/file`, body, {
        params: {...getDefParams()}
    });
};

const addConsultationFile = (id, body) => {
    return serviceInterface.post(`/file/consultation/${id}`, body, {
        params: {...getDefParams()}
    });
};

const addNoteFile = (id, body) => {
    return serviceInterface.post(`/file/note/${id}`, body, {
        params: {...getDefParams()}
    });
};

const deleteFile = (id) => {
    return serviceInterface.delete(`/file/${id}`, {
        params: {...getDefParams()}
    });
};
export default {
    addPatientImage,
    update,
    addNoteFile,
    get,
    addConsultationFile,
    getNoteFiles,
    deleteFile,
    getConsultationFiles,
    downloadFile
};
