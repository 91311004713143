import React, {useEffect, useState} from 'react';
import {
    Dialog, DialogContent, DialogTitle,
    IconButton, Tooltip,
} from '@mui/material';
import bookmarkCategoryAPI from "../../services/bookmarkCategoryAPI";
import BookmarkTable from "./BookmarkTable";
import {BookmarkBorder} from "@mui/icons-material";
import Loader from "../Loader";
import {useSnackbar} from "notistack";
import MyDialogTitle from "../DialogTitle";

const Bookmarks = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (open) {
            loadCategories()
        }
    }, [open])


    const loadCategories = async () => {
        setLoading(true);
        const result = await bookmarkCategoryAPI.list();
        setCategories(result.data)
        setLoading(false)
    }

    const deleteBookmark = async (id) => {
        try {
            await bookmarkCategoryAPI.deleteBookmark(id)
            await loadCategories()
            enqueueSnackbar('Bookmark removed', {
                variant: 'success'
            });
        } catch (e) {
            enqueueSnackbar('Bookmark could not be removed', {
                variant: 'success'
            });
        }
    }


    return (
        <>
            <Tooltip title={'Bookmarks'} placement={'right'} arrow={true}>
                <IconButton
                    onClick={handleOpen}
                >
                    <BookmarkBorder/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                fullWidth
                onClose={handleOpen}
                maxWidth={'md'}
                keepMounted
            >
                <MyDialogTitle handleOpen={handleOpen}>
                    Your bookmarks
                </MyDialogTitle>

                <DialogContent>
                    {!loading ?
                        <BookmarkTable
                            handleOpen={handleOpen}
                            deleteBookmark={deleteBookmark}
                            setCategories={setCategories}
                            categories={categories}
                        /> : <Loader/>}
                </DialogContent>
            </Dialog>
        </>
    )
        ;
};

export default Bookmarks;
