import { apis, serviceInterface } from "./config";

const listTypes = (params) => {

    console.log("listTypesxxx", listTypes)

    var data = serviceInterface.get(apis.LIST_TRANSLATION_TYPES, { params });

    return data;
};

const count = (params) => {
    return serviceInterface.get('/translations/count', {
        params
    });
};

const list = (params) => {
    return serviceInterface.get('/translations/list', {
        params
    });
};

const search = (params) => {
    return serviceInterface.get(`/translations/search`, {
        params
    });
};


const listVersions = (params) => {
    return serviceInterface.get(apis.LIST_TRANSLATION_VERSIONS, {
        params
    });
};

const getUntraslatedRubric = (params) => {
    return serviceInterface.get(apis.GET_UNTRASLATED_RUBRIC, {
        params
    });
};

const updateStatus = (params) => {
    return serviceInterface.post(`/translations/update-status`,
        params
    );
};

const save = (params) => {
    return serviceInterface.post(apis.SAVE_TRANSLATIONS,
        params
    );
};

const getContext = (params) => {
    return serviceInterface.get('/translations/context',{
            params
        }
    );
};

export default {
    listTypes,
    list,
    save,
    getUntraslatedRubric,
    count,
    getContext,
    updateStatus,
    listVersions,
    search
};
