import React from "react";
import Separator from "../../Separator";
import {Grid} from "@mui/material";
import FileItem from "../../TabContents/ConsultationContent/ConsultationEditForm/FilesDropzone/FileItem";
import fileAPI from "../../../services/fileAPI";
import {useSnackbar} from "notistack";

export default ({files, setFiles}) => {
    const {enqueueSnackbar} = useSnackbar();


    const handleRemoveFile = async (id) => {
        try {
            const file = files.find(file => file.id == id);
            await fileAPI.deleteFile(file.id)
            setFiles(files.filter((file) => file.id !== id));
            enqueueSnackbar(`File deleted`, {
                variant: 'success'
            });
        } catch (e) {
            enqueueSnackbar(`Files could not be deleted`, {
                variant: 'error'
            });
        }
    };

    const addValue = (e, index, field) => {
        files[index][field] = e.target.value;
        setFiles(files);
    }


    return (
        <>
            {files.length ?
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Separator>
                                Multimedia
                            </Separator>
                        </Grid>
                        {files.map((file, index) =>
                            <Grid item xs={4}>
                                <FileItem
                                    file={file}
                                    index={index}
                                    addValue={addValue}
                                    handleRemove={handleRemoveFile}
                                />
                            </Grid>
                        )}
                    </Grid>
                : <></>
            }
        </>
    )
}