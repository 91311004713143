import React, {useEffect, useMemo, useState} from 'react';
import Editor from "./editor";
import fileAPI from "../../services/fileAPI";
import noteDirectoryAPI from "../../services/noteDirectoryAPI";
import debounce from "lodash.debounce";
import {Box, Grid, IconButton, TextField, Typography} from "@mui/material";
import {X} from "react-feather";
import {useSnackbar} from "notistack";
import useFormatDate from "../../hooks/useFormatDate";

export default ({note, loadFiles}) => {
    const {enqueueSnackbar} = useSnackbar();

    const [model, setModel] = useState("");

    useEffect(() => {
        setModel(note.content || '')
    }, [note])

    const uploadFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append(`file`, file);
            formData.append('name', file.name)
            await fileAPI.addNoteFile(note.id, formData);
            await loadFiles(note.id);
        } catch (e) {
            enqueueSnackbar(`File could not be updated`, {
                variant: 'error'
            });
        }

    }

    const onContentChange = (content) => {
        noteDirectoryAPI.updateNote({
            ...note,
            content
        })
    }

    const debouncedOnContentChange = useMemo(
        () => debounce(onContentChange, 400),
        []);

    return (
        <>

            <Editor
                uploadFile={uploadFile}
                handleContentChange={debouncedOnContentChange}
                model={model}
                setModel={setModel}
            />
        </>
    )
}