import {apis, serviceInterface} from "./config";

const getRepertories = (params) => {
    return serviceInterface.get(apis.GET_REPERTORIES, {
        params:params,
    });
};

const getRepertory = (params) => {
    return serviceInterface.get(`documents/${params.documentId}`);
};

const getRepertoryViews = (params) => {
    return serviceInterface.get(`views`, {
        params: params,
    });
};

export default {
    getRepertories,
    getRepertory,
    getRepertoryViews,
};
