import React, {
    createContext,
    useReducer
} from 'react';
import translationAPI from "../services/translationAPI";

const initialAuthState = {
    translations: {},
    translationList: {
        data: [],
        total: 0,
        statusUpdates: {}
    },
    types: [ //TODO: this array should be filled by listTypes() but it isn't.
        {
            "id": "RV",
            "name": "To be reviewed"
        },
        {
            "id": "TT",
            "name": "Translated"
        },
        {
            "id": "NT",
            "name": "To translate"
        }
    ]
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'HANDLETRANSLATIONS': {
            const {translations, rubric} = action.payload;
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [`${rubric.id}-${rubric.documentId}`]: translations
                }
            };
        }
        case 'LISTTYPES': {
            const {types} = action.payload;
        
            return {
                ...state,
                types
            };
        }
        case 'LISTTRANSLATIONS': {
            const {translationList} = action.payload;
    
            return {
                ...state,
                translationList
            };
        }
        case 'SETSTATUSUPDATES': {
            const {updates} = action.payload;
        
            return {
                ...state,
                translationList: {
                    ...state.translationList,
                    statusUpdates: updates
                }
            };
        }
        default: {
            return {...state};
        }
    }
};

const TranslationContext = createContext({
    ...initialAuthState
});

export const TranslationProvider = ({children}) => {
    
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    
    const addTranslation = ({rubric, translations}) => {
        dispatch({
            type: 'HANDLETRANSLATIONS',
            payload: {
                translations,
                rubric
            }
        });
    };
    
    const listTranslations = async (params) => {
    
        const result = await translationAPI.search(params);
        
        dispatch({
            type: 'LISTTRANSLATIONS',
            payload: {
                translationList: {
                    data: result.data,
                    total: result.total
                }
            }
        });
    };
    
    const setStatusUpdates = (updates) => {
        dispatch({
            type: 'SETSTATUSUPDATES',
            payload: {
                updates
            }
        });
    };
    
    const listTypes = async () => {

        //console.log("trContext:listTypes");

        const result = await translationAPI.listTypes();

        //console.log("trContext:listTypes:result", result);
    
        dispatch({
            type: 'LISTTYPES',
            payload: {
                types: result.data
            }
        });
    
    };
    
    
    return (
        <TranslationContext.Provider
            value={{
                ...state,
                addTranslation,
                listTypes,
                listTranslations,
                setStatusUpdates
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};

export default TranslationContext;
