import { apis,  serviceInterface } from "./config";

const getRequestUser = (params) => {
    return serviceInterface.get('/user/get', {
        params
    });
};

const addFavDoc = (documentId) => {
    return serviceInterface.post(`/user/document/${documentId}`)
};

const removeFavDoc = (documentId) => {
    return serviceInterface.delete(`/user/document/${documentId}`)
};


const listTranslators = (params={}) => {
    params.role = 'TRA';
    return serviceInterface.get(`/user/list`, {
        params
    });
};

export default {
    getRequestUser,
    listTranslators,
    addFavDoc,
    removeFavDoc
};
