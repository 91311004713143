import React from "react";
import {Box, Button, Grid, IconButton, TextField,} from "@mui/material";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import fieldAPI from "../../../../services/fieldAPI";
import LoadingScreen from "../../../LoadingScreen";
import {Plus} from "react-feather";
import {DragIndicator} from "@mui/icons-material";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useQuery} from "react-query";

export default () => {

    const {enqueueSnackbar} = useSnackbar();

    const fetchFields = async () => {
        const result = await fieldAPI.listFreeFields();
        formik.setFieldValue('fields', result.data.fields)
    }

    const {isFetching} = useQuery('fields', fetchFields, {
        refetchOnWindowFocus: false
    });


    const formik = useFormik({
        validateOnChange: false,
        enableReinitialize: false,
        initialValues: {
            fields: []
        },
        async onSubmit(values, {setFieldError}) {
            try {
                await fieldAPI.updateFreeFields(values)

                enqueueSnackbar('Fields updated', {
                    variant: 'success'
                });
            } catch (e) {
                const errors = e.response.data.data
                enqueueSnackbar(`There are  ${errors.length} errors`, {
                    variant: 'error'
                });
                errors.map(el => {
                    let field = el.field
                        .replace('/', '')
                        .replaceAll('/', '.');
                    setFieldError(field, el.message);
                });
            }
        }
    });

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = Array.from(formik.values.fields);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        formik.setFieldValue('fields', newItems);
    };


    const handleAdd = () => {
        formik.values.fields.push({userText: '', id: `test-${Math.random()}`})
        formik.setFieldValue('fields', formik.values.fields)
    }

    const handleValue = (value, index, field) => {

        const updatedFields = [...formik.values.fields];
        updatedFields[index] = {
            ...updatedFields[index],
            [field]: value
        };
        formik.setFieldValue('fields', updatedFields);

    };

    return (
        <form noValidate onSubmit={formik.handleSubmit} autoComplete='off'>


            { isFetching ? <LoadingScreen/> :
                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="items">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <Grid container spacing={2}>
                                        {formik.values.fields.map((item, index) => (
                                            <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                {(provided) => (
                                                    <Grid item
                                                          xs={12} {...provided.draggableProps} {...provided.dragHandleProps}
                                                          ref={provided.innerRef}
                                                    >
                                                        <Box sx={{display: 'flex', alignContent: 'center'}}>
                                                            <DragIndicator/>
                                                            <TextField
                                                                label={`Item ${index + 1}`}
                                                                defaultValue={item.userText}
                                                                variant="outlined"
                                                                onChange={(e) => handleValue(e.target.value, index, 'userText')}
                                                                fullWidth
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Grid>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Grid item xs={4}>
                        <IconButton onClick={handleAdd}>
                            <Plus/>
                        </IconButton>
                        <Grid item xs={12}>
                            <Button
                                color='primary'
                                type={'submit'}
                                variant={"contained"}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </form>
    )

};

