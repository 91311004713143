import React from 'react';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import useAuth from "../hooks/useAuth";
import {createMuiTheme} from "../theme";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {IntlProvider} from "react-intl";
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import useSettings from "../hooks/useSettings";

export default ({children}) => {
    const {locale, dateLocale} = useAuth();
    const { theme: themeString} = useSettings();

    const theme = createMuiTheme({theme: themeString});

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale.code?.replace('-', '')]),
        [locale, theme],
    );

    return (
        <ThemeProvider theme={themeWithLocale}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={dateLocale}
            >
                <IntlProvider locale={locale.code || 'en'}>
                    {children}
                </IntlProvider>
            </LocalizationProvider>
        </ThemeProvider>
    )
}