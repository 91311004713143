import {apis,  serviceInterface} from "./config";

const getChapters = (params) => {
    return serviceInterface.get(apis.GET_CHAPTERS, {
            params: params,
        },
    );
};

export default {
    getChapters,
};
