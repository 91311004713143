import { serviceInterface} from "./config";


const list = (params) => {
    return serviceInterface.get('/note-directories', {
        params
    });
};

const create = (params) => {
    return serviceInterface.post('/note-directories', params);
};

const createNote = (params) => {
    return serviceInterface.post('/note', params);
};

const updateNote = (params) => {
    return serviceInterface.put('/note', params);
};


const update = (params) => {
    return serviceInterface.put('/note-directories', params);
};

const deleteDir = (noteDirectoryId) => {
    return serviceInterface.delete(`/note-directories/${noteDirectoryId}`);
};

const deleteNote = (noteId) => {
    return serviceInterface.delete(`/note/${noteId}`);
};

const getNote = (noteId) => {
    return serviceInterface.get(`/notes/${noteId}`);
};


export default {
    list,
    getNote,
    deleteDir,
    updateNote,
    createNote,
    deleteNote,
    update,
    create
}
