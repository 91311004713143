import React, {useState} from "react";
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    Grid, TextField, IconButton, FormControlLabel, Checkbox, Box, Typography, Tooltip
} from "@mui/material";
import {useSnackbar} from 'notistack';
import MyDialogTitle from "../DialogTitle";
import useClipboard from "../../hooks/useClipboard";
import InventoryIcon from '@mui/icons-material/Inventory';
import {useFormik} from "formik";
import {AirplaneTicket, CheckOutlined, Delete} from "@mui/icons-material";
import clipboardAPI from "../../services/clipboardAPI";

const ManagementDialog = ({text}) => {
    const {repertorization, list} = useClipboard();
    const [showDialog, setShowDialog] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => setShowDialog(false);

    const handleDialogOpen = () => {
        setShowDialog(true)
    }

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {
            clipboards: repertorization.clipboards?.map(clip => {
                clip.toDelete = false;
                return clip
            })
        },
        async onSubmit(values) {
            try {
                await clipboardAPI.modifyInfo(values)
                enqueueSnackbar(`Clipboards updated`, {
                    variant: 'success'
                });
                handleClose()
                list({})
            } catch (e) {
                enqueueSnackbar(`Clipboards could not be updated `, {
                    variant: 'error'
                });
            }
        }
    });

    const handleDelete = (i) => {
        const newClipboards = [...formik.values.clipboards];
        newClipboards[i] = {
            ...newClipboards[i],
            toDelete: !newClipboards[i].toDelete,
        };
        formik.setFieldValue('clipboards', newClipboards);
    }

    const handleName = (e, i) => {
        formik.values.clipboards[i].name = e.target.value
        formik.setFieldValue('clipboards', formik.values.clipboards)
    }

    const handleDefault = (e, i) => {
        if (e.target.checked) {
            formik.values.clipboards.map((clip, index) => {
                clip.default = index === i
                return clip
            })
        }
        formik.setFieldValue('clipboards', formik.values.clipboards)

    }

    return (
        <>
            <Tooltip title={text} placement={'bottom'} arrow={true}>
                <IconButton
                    sx={{marginLeft: '.5rem', color: 'white'}}
                    onClick={handleDialogOpen}
                >
                    <InventoryIcon/>
                </IconButton>
            </Tooltip>

            {showDialog &&
                <Dialog
                    open={showDialog}
                    fullWidth
                    maxWidth={'md'}
                    keepMounted
                    onClose={handleClose}
                >
                    <form noValidate onSubmit={formik.handleSubmit} autoComplete='off'>
                        <MyDialogTitle handleOpen={handleClose}>
                            Manage clipboards. <br/>
                            {text}
                        </MyDialogTitle>
                        <DialogContent>
                            {
                                formik.values.clipboards.map((clip, i) =>
                                    <Grid container alignItems={'center'} mt={1} spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography>
                                                Num of rubrics: {clip.rubrics.length}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                fullWidth
                                                label={'Name'}
                                                value={clip.name}
                                                variant={'outlined'}
                                                disabled={clip.toDelete}
                                                onChange={(e) => handleName(e, i)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={clip.toDelete}
                                                        onChange={(e) => handleDefault(e, i)}
                                                        checked={clip.default || false}
                                                    />
                                                }
                                                label={'Default'}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box display={'flex'} justifyContent={'flex-end'}>
                                                <IconButton onClick={() => handleDelete(i)}>
                                                    {clip.toDelete ? <CheckOutlined/> : <Delete/>}
                                                </IconButton>
                                            </Box>

                                        </Grid>
                                    </Grid>
                                )
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button
                                color='primary'
                                type={'submit'}
                                variant={"contained"}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>

                </Dialog>}
        </>
    )
}

export default ManagementDialog;
