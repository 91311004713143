import {apis, serviceInterface} from "./config";

const add = (clipboard) => {
    return serviceInterface.post(apis.CREATE_CLIPBOARD, clipboard,
    );
};

const getGrid = (params) => {
    return serviceInterface.get('/clipboard/grid', {
        params
    });
};


const list = (params) => {
    return serviceInterface.get('/clipboards', {
        params
    });
};

const addRubric = ({id, clipboardRubric}) => {
    return serviceInterface.post(apis.ADD_CLIPBOARD_RUBRIC,
        {
            id,
            clipboardRubric
        }
    );
};

const update = (clipboard) => {
    return serviceInterface.put('/clipboard',clipboard);
};

const modifyInfo = (clipboard) => {
    return serviceInterface.put('/clipboard/info',clipboard);
};


const updateRubric = (body) => {
    return serviceInterface.put('/clipboard/rubric',body);
};

const removeRubric = (clipboardRubricId) => {
    return serviceInterface.delete(`/clipboard/remove-rubric/${clipboardRubricId}`);
};

export default {
    add,
    getGrid,
    addRubric,
    modifyInfo,
    list,
    update,
    removeRubric,
    updateRubric
}
