import React, {
    useState,
    useRef, useEffect
} from 'react';
import {
    Autocomplete,
    Box, Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Settings as SettingsIcon} from 'react-feather';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import useAuth from "../../../hooks/useAuth";
import useConstants from "../../../hooks/useConstants";
import AdvancedSettings from "./AdvancedSettings";
import useSettings from "../../../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5,
    },
    popover: {
        width: 320,
        padding: 20

    }
}))

const dateLocales = [{id: 'en', name: 'MM/DD/YYYY'}, {id: 'en-gb', name: 'DD/MM/YYYY'}, {id: 'de', name: 'DD.MM.YYYY'}];

const themes = [
    {
        id: 'darkTheme',
        name: 'Dark theme'
    },
    {
        id: 'muiTheme',
        name: 'Light theme'
    }
]


const Settings = ({}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const {theme, handleThemeChange, open: advancedSettingsOpen, handleOpen: setAdvancedSettingsOpen} = useSettings();
    const {locale, dateLocale, changeLang, changeLocale, changeDateLocale} = useAuth();
    const {locales = [], listLocales} = useConstants();

    useEffect(() => {
        !locales?.length && listLocales()
    }, [])

    const handleOpen = () => {
        setOpen(!isOpen);
    };

    const handleAdvancedSettingsOpen = () => {
        setOpen(false);
        setAdvancedSettingsOpen(!advancedSettingsOpen)
    }

    const handleLocaleChange = async (e) => {
        const locale = locales.filter(l => l.id == e.target.value)[0];
        // await i18n.addResources('ru', 'translation', prova)
        await i18n.changeLanguage(locale.language?.abbr);
        changeLang({lang: locale.language?.abbr});
        changeLocale({locale: locale});

    };


    const handleDateLocaleChange = (e) => {
        changeDateLocale({dateLocale: e.target.value});
    }


    return (
        <>
            <Tooltip title={t('settings')}>
                <IconButton
                    sx={{color: 'white'}}
                    onClick={handleOpen}
                    ref={ref}
                >
                    <SettingsIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{paper: classes.popover}}
                anchorEl={ref.current}
                onClose={handleOpen}
                open={isOpen}
            >
                <Typography
                    variant="h6"
                    color="textPrimary"
                >
                    {t('settings')}
                </Typography>
                <Box mt={2}>
                    <TextField
                        fullWidth
                        label={t('lang')}
                        value={locale?.id}
                        onChange={handleLocaleChange}
                        select
                        SelectProps={{native: true}}
                        variant="outlined"
                    >
                        {locales?.map((locale) => (
                            <option
                                key={locale.id}
                                value={locale.id}
                            >
                                {locale.description}
                            </option>
                        ))}
                    </TextField>
                </Box>
                <Box mt={2}>
                    <TextField
                        fullWidth
                        label={'theme'}
                        value={theme}
                        onChange={(e) => handleThemeChange(e.target.value)}
                        select
                        SelectProps={{native: true}}
                        variant="outlined"
                    >
                        {themes?.map((theme) => (
                            <option
                                key={theme.id}
                                value={theme.id}
                            >
                                {theme.name}
                            </option>
                        ))}
                    </TextField>
                </Box>
                <Box mt={2}/>
                <TextField
                    fullWidth
                    label={'Date locale'}
                    defaultValue={dateLocale}
                    onChange={handleDateLocaleChange}
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                >
                    {dateLocales?.map((locale) => (
                        <option
                            key={locale.id}
                            value={locale.id}
                        >
                            {locale.name}
                        </option>
                    ))}
                </TextField>

                <Box mt={4}/>
                <Button
                    color={'secondary'}
                    variant='contained'
                    onClick={handleAdvancedSettingsOpen}
                >
                    Advanced Settings
                </Button>
            </Popover>
            <AdvancedSettings
                open={advancedSettingsOpen}
                handleOpen={handleAdvancedSettingsOpen}
            />

        </>
    );
}

export default connect(
    ({config}) => ({config})
)(Settings);
