import React, {useEffect, useState} from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import versionAPI from "./services/versionAPI";

const VersionControl = () => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        checkVersion()
        const intervalId = setInterval(() => checkVersion(), 300000)
        return () => clearInterval(intervalId);
    }, [])

    const checkVersion = async () => {
        try {
            const result = await versionAPI.getVersion()
            const version = result.data
            if (version !== localStorage.getItem('version')) {
                setOpen(true)
            }
            localStorage.setItem('version', version)
        } catch (e) {
            console.log(e)
        }
    }

    const reload = () => {
        window.location.reload()
    }
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'md'}
            keepMounted
        >
            <DialogTitle>
                Opusplus has been updated!
            </DialogTitle>

            <DialogContent>
                There is a new version of OpusPlus available!
            </DialogContent>
            
            <DialogActions>
                <Button
                    color={'secondary'}
                    variant='contained'
                    onClick={reload}
                >
                    Click to reload!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VersionControl;
