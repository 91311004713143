export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "Répertoires",
                patients: "Patients",
            }
        },
        rubricOptions: {
            take: "Prendre",
            view: "Voir",
            saveAsBookmark: "Enregistrer comme favori",
            addRemedy: "Ajouter un remède"
        },
        repertory: {
            settings: "Paramètres du répertoire",
            languages: 'Langues du répertoire',
            mainLanguage: 'Langue principale'
        },
        search: "Recherche",
        settings: "Paramètres",
        lang: "Langue",
        save: "Enregistrer",
        selectRemedy: "Sélectionner un remède",
        languages: 'Langues',
        description: "Description",
        close: "Fermer",
        note: "Notes"
    }
}