import React from "react";
import {Box, CircularProgress} from "@mui/material";

const Loader = ({size }) =>
    <Box sx={{
        display: 'flex',
        marginLeft: '2rem'
    }}>
        <CircularProgress
            size={size || 60}
            thickness={4}
            disableShrink
        />
    </Box>;

export default Loader;
