export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "Repertorien",
                patients: "Patienten",
            }
        },
        rubricOptions: {
            take: "Nehmen",
            view: "Anzeigen",
            saveAsBookmark: "Als Lesezeichen speichern",
            addRemedy: "Heilmittel hinzufügen"
        },
        repertory: {
            settings: "Repertoriumseinstellungen",
            languages: 'Repertoriumssprachen',
            mainLanguage: 'Hauptsprache'
        },
        search: "Suche",
        settings: "Einstellungen",
        lang: "Sprache",
        save: "Speichern",
        selectRemedy: "Heilmittel auswählen",
        languages: 'Sprachen',
        description: "Beschreibung",
        close: "Schließen",
        note: "Notizen"
    }
}