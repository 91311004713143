import * as React from 'react'
import {renderToString} from 'react-dom/server'
import remedyAPI from '../../services/remedyAPI'
import {useEffect, useMemo, useState} from "react";
import debounce from "lodash.debounce";
import wordAPI from "../../services/wordAPI";


const useOptions = () => {

    const searchRemedy = async (str, cb) => {
        const result = await remedyAPI.search({str});
        cb(result.data)
    }

    const debouncedSearchRemedy = useMemo(
        () => debounce(searchRemedy, 400),
        []);

    const searchWord = async (search, cb) => {
        const result = await wordAPI.search({search});
        cb(result.data)
    }

    const debouncedSearchWord = useMemo(
        () => debounce(searchWord, 400),
        []);

    const mentionStyle = {
        fontWeight: 'bold',
        padding: '10px',
        borderRadius: '25px'
    }


    const options = {
        collection: [
            {
                trigger: '$',
                values: async (search, cb) => {
                    debouncedSearchRemedy(search, cb)
                },
                lookup: (remedy) => `${remedy.name} {${remedy.abbr}}`,
                fillAttr: 'name',
                allowSpaces: true,
                selectTemplate: (item) => {
                    console.log("item", item)
                    // You can run various logics in here
                    return renderToString(
                        <span style={{...mentionStyle, color: 'green'}}>
                         {`${item.original.name} {${item.original.abbr}}`}
			            </span>
                    )
                }
            },
            {
                trigger: '@',
                values: async (search, cb) => {
                    debouncedSearchWord(search, cb)
                },
                lookup: (word) => word.name,
                fillAttr: 'name',
                allowSpaces: true,
                selectTemplate: (item) => {
                    return renderToString(
                        <span
                            style={{...mentionStyle, color: 'red'}}>
                          {item.original.name}
                        </span>
                    )
                }
            }
        ]

    }


    return options;
};

export default useOptions;


