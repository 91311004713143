import React, {useRef, useEffect, useState} from 'react';

import {
    makeStyles,
} from '@mui/styles';

import {
    Box,
    ButtonBase,
    Typography,
     Avatar, MenuItem, Menu
} from '@mui/material';
import useAuth from "../../../hooks/useAuth";
import {useSnackbar} from "notistack";
import authAPI from '../../../services/authAPI';
import useConstants from "../../../hooks/useConstants";

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32
    },
    name: {
        fontWeight: '400',
        color: 'white'
    },
    popover: {
        width: 200
    }
}));


const Account = () => {
    const classes = useStyles();
    const {user} = useAuth();
    const ref = useRef(null);
    const {enqueueSnackbar} = useSnackbar();
    const [isOpen, setOpen] = useState(false);
    const {getApp} = useConstants();
    const app = getApp();

    useEffect(() => {
        document.title = app.name;
    }, []);
    
    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleLogout = async () => {
        handleClose();
        await authAPI.logout();
        enqueueSnackbar('Logout', {
            variant: 'success'
        });
        setTimeout(() => history.push('/'), 700);
        
    }

    return (
        <>
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    ml={2}
                    component={ButtonBase}
                    onClick={handleOpen}
                    ref={ref}
                >
                    <Typography
                        variant="body1"
                        style={{fontWeight:'bold'}}
                        color="secondary"
                        className={classes.name}
                    >
                        {user?.name}{' '}{user?.surname}
                    </Typography>
                    
                    <Box ml={1}/>
                    <Avatar
                        alt="User"
                        className={classes.avatar}
                        src={user?.avatar}
                    />
                </Box>
                <Menu
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    keepMounted
                    PaperProps={{className: classes.popover}}
                    anchorEl={ref.current}
                    open={isOpen}
                >
                    <MenuItem onClick={handleLogout}>
                        Logout
                    </MenuItem>
                </Menu>
            </>
        
        </>
    );
}

export default Account;
