import {getDefParams, serviceInterface} from "./config";

const listLanguages = () => {
    return serviceInterface.get(`/lookup/list/langs`);
};

const listCountries = (params) => {
    return serviceInterface.get(`/countries`, {
        params: {...getDefParams(), ...params},
    });
};

const listLocales = () => {
    return serviceInterface.get(`/lookup/list/locales`);
};

const listLabels = () => {
    return serviceInterface.get(`/lookup/list/labels`);
};

const listEvaluations = () => {
    return serviceInterface.get(`/lookup/list/evaluations`);
};


const listLookups = (params) => {
    return serviceInterface.get(`/lookup/list`, {
       params,
    });
}

const createLookup = (body) => {
    return serviceInterface.post(`/lookup`, body);
}
const updateLookup = (body) => {
    return serviceInterface.put(`/lookup`, body);
}


const deleteLookup = (id) => {
    return serviceInterface.delete(`/lookup/${id}`);
}

export default {
    listLanguages,
    listLabels,
    listLocales,
    listEvaluations,
    createLookup,
    listLookups,
    listCountries,
    deleteLookup,
    updateLookup
};
