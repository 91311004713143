import {defaultTheme} from "../../../theme";

const sections = [
    {
        items: [
            {
                id: 'nav-repertories',
                icon: {
                    name: 'LibraryBooks',
                    color: defaultTheme.repertoryColor
                },
                title: 'tableOfContent.titles.repertories',
                href: '/repertories'
            },
            {
                id: 'nav-materia-medica',
                icon: {
                    name: 'BusinessCenter',
                    color: defaultTheme.materiaMedicaColor
                },
                href: '/materia-medica',
                title: 'tableOfContent.titles.materiaMedica',

            },
            {
                id: 'nav-1',
                icon: {
                    name: 'PatientCircle',
                    color: defaultTheme.patientColor
                },
                href: '/patients',
                title: 'tableOfContent.titles.patients',
            },
            {
                id: 'nav-5',
                icon: {
                    name: 'Remedy',
                    color: defaultTheme.remedyColor
                },
                href: '/remedies',
                title: 'tableOfContent.titles.remedies',
            },
            {
                id: 'nav-authors',
                icon: {
                    name: 'Author',
                    color: defaultTheme.authorColor
                },
                href: '/authors',
                title: 'tableOfContent.titles.authors',
            },
        ]
    }
];

export default sections;
