import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en'
import it from './translations/it'
import fr from './translations/fr'
import zh from './translations/zh'
import de from './translations/de'
import ja from './translations/ja'
import {getSettings} from "./utils";

i18n.use(LanguageDetector)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: getSettings()?.lang || 'en',
        fallbackLng: 'en',
        whitelist: ['en', 'it'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en,
            zh,
            de,
            ja,
            it,
            fr
        }
    });

export default i18n;