import {apis, serviceInterface} from "./config";

const add = (bookmarkCategory) => {
    return serviceInterface.post('/bookmark-category', bookmarkCategory,
    );
};


const list = (params) => {
    return serviceInterface.get('/bookmark-categories', {
        params
    });
};

const addBookmark = (body) => {
    return serviceInterface.post('/bookmark',
        body
    );
};

const deleteBookmark = (id) => {
    return serviceInterface.delete(`/bookmark/${id}`)
};

export default {
    add,
    addBookmark,
    list,
    deleteBookmark
}
