import { serviceInterface, getDefParams } from "./config";

const assignRepertorization = ( repertorizationId, consultationId) => {
    return serviceInterface.post(`/repertorization/${repertorizationId}/assign/${consultationId}`, {}, {
        params: {...getDefParams()}
    });
};


const list = (params) => {
    return serviceInterface.get('/repertorization', {
        params: params,
    });
};


export default {
    list,
    assignRepertorization
};
