import React from 'react';
import {
    AppBar,
    IconButton,
    Toolbar,
    Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Settings from "./Settings";
import Clipboards from "../../Clipboards";
import {connect} from "react-redux";
import {SET_COLLAPSE} from "../../../redux/config";
import Account from "./Account";
import useConstants from "../../../hooks/useConstants";


const TopBar = ({
                    className,
                    config,
                    setCollapse,
                    onMobileNavOpen,
                    ...rest
                }) => {

    const {getApp} = useConstants();
    const app = getApp();

    const siteSign = {
        color: 'blue',
        fontSize: '20px',
        textAlign: 'center',
        height: '10px',
        backgroundColor: '#EAEAEA'
    }

    return (
        <>
            <AppBar
                sx={{
                    zIndex: "zIndex.drawer" + 100,
                    boxShadow: 'none',
                }}
                {...rest}
            >
                <Toolbar
                    sx={{
                        maxHeight: 64
                    }}
                >
                    <Box sx={{display: {lg: 'none', xs: 'block'}}}>
                        <IconButton
                            tabIndex={-1}
                            onClick={onMobileNavOpen}
                        >
                            <MenuIcon color={'secondary'}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ display: {lg: 'block', xs: 'none', marginRight: '3rem'}}}/>
                    <Clipboards/>
                    <Box
                        ml={2}
                        flexGrow={1}
                    />
                    <Settings/>
                    <Account/>
                </Toolbar>
            </AppBar>
        </>
    );
};


export default connect(
    ({config, tabs}) => ({config, tabs}),
    (dispatch) => ({
        setCollapse: (payload) => dispatch(SET_COLLAPSE(payload)),
    })
)(TopBar);

