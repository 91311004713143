import React, {useState} from "react";
import {Plus} from "react-feather";
import {IconButton, Menu, MenuItem} from "@mui/material";

export default ({ handleAddNote, handleAddFolder}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Plus/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    handleClose(e);
                    handleAddFolder(e);
                }}
                >
                    Add new folder
                </MenuItem>
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    handleClose(e);
                    handleAddNote(e);
                }}
                >
                    Add new note
                </MenuItem>
            </Menu>
        </div>
    );
}