import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import patientsAPI from "../services/patientAPI";
import {SET_SELECTED_PATIENT} from "./tabs";
import {SET_RESULTS} from "./search";

export const GET_PATIENTS = createAsyncThunk(
    "@@PATIENTS/GET_PATIENTS",
    async (params, thunkAPI) => {
        const response = await patientsAPI.search(params);
        const state = thunkAPI.getState();
        
        const prevPatients = state?.search?.patients?.data;
        
        const data = prevPatients && params.page != 1  ?  JSON.parse(JSON.stringify(prevPatients)) : {};
        
        (response?.data || []).forEach((r) => {
            data[r.id] = r
        })
        
        thunkAPI.dispatch(
            SET_RESULTS({
                key: "patients",
                data: {...response, data},
            })
        );
        return {...params, ...response};
    }
);


export const GET_PATIENT = createAsyncThunk(
    "@@PATIENTS/GET_PATIENT",
    async (params, thunkAPI) => {
        const {data} = await patientsAPI.get(params);
        
        thunkAPI.dispatch(
            SET_SELECTED_PATIENT({
                id: data.id
            })
        );
        
        return {...params, ...data};
    }
);


const patientsSlice = createSlice({
    name: "@@PATIENTS",
    initialState: {},
    reducers: {},
    extraReducers: {
        [GET_PATIENT.fulfilled]: (state, action) => {
            const data = action.payload;
            const patients = {};
            patients[data.id] = data;
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    patients: false,
                },
                ...patients,
            };
        },
        [GET_PATIENTS.pending]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                patients: true,
            },
        }),
        [GET_PATIENTS.fulfilled]: (state, action) => {
            const {data} = action.payload;
            const patients = {};
            (data || []).forEach((r) => (patients[r.id] = r));
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    patients: false,
                },
                ...patients,
            };
        },
        [GET_PATIENTS.rejected]: (state) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                patients: false,
            },
        })
        
    },
});

export default patientsSlice.reducer;
