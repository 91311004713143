import React, {
    createContext,
    useReducer
} from 'react';
import {getSettings, setSettings} from "../utils";

const initialRepertoryState = {};


const reducer = (state, action) => {
    switch (action.type) {

        case 'SETSETTINGS': {
            const {id, settings} = action.payload;

            return {
                ...state,
                [id]: settings
            }
        }

        case 'CHANGEMAINLANG': {
            const {id, mainLang} = action.payload;

            const settings = getSettings()?.[id] || {}
            setSettings(id, {
                ...settings,
                mainLang
            })

            return {
                ...state,
                [id]: {
                    ...state[id],
                    mainLang
                }
            };
        }
        case 'CHANGELANGS': {
            const {id, langs} = action.payload;
            const settings = getSettings()?.[id] || {}
            setSettings(id, {
                ...settings,
                langs
            })
            return {
                ...state,
                [id]: {
                    ...state[id],
                    langs
                }
            };
        }
        default: {
            return {...state};
        }
    }
};

const RepertoryContext = createContext({
    ...initialRepertoryState,
});

export const RepertoryProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialRepertoryState);

    const changeMainLang = async ({id, mainLang}) => {
        dispatch({
            type: 'CHANGEMAINLANG',
            payload: {
                id,
                mainLang
            }
        });
    };

    const changeLangs = async ({id, langs}) => {
        dispatch({
            type: 'CHANGELANGS',
            payload: {
                id,
                langs
            }
        });
    };

    const getRepertorySettings = (id) => {
        return state[id] || getSettings()[id]
    }

    return (
        <RepertoryContext.Provider
            value={{
                ...state,
                getRepertorySettings,
                changeMainLang,
                changeLangs
            }}
        >
            {children}
        </RepertoryContext.Provider>
    );
};

export default RepertoryContext;
