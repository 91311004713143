export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "レパートリー",
                patients: "患者",
            }
        },
        rubricOptions: {
            take: "選ぶ",
            view: "表示",
            saveAsBookmark: "ブックマークとして保存",
            addRemedy: "薬剤を追加"
        },
        repertory: {
            settings: "レパートリーの設定",
            languages: 'レパートリーの言語',
            mainLanguage: 'メイン言語'
        },
        search: "検索",
        settings: "設定",
        lang: "言語",
        save: "保存",
        selectRemedy: "薬剤を選択",
        languages: '言語',
        description: "説明",
        close: "閉じる",
        note: "メモ"
    }
}