import React from "react";
import {Box, Typography} from "@mui/material";


const Separator = ({children}) => {
    return (
        <Box
            sx={{
                width: "100%",
                marginTop: (theme) => theme.spacing(2),
                borderTop: (theme) =>  `1px solid ${theme.palette.action.active}`,
                position: 'relative',
                display: 'flex',
                justifyContent:'center'
            }}
        >
            {children &&
                <Typography
                    variant={'body1'}
                    sx={{
                        position: 'absolute',
                        bottom: -9,
                        padding: '0 10px 0 10px',
                        backgroundColor: (theme) => theme.palette.background.default,
                        textTransform: "uppercase",
                        fontSize: 14,
                    }}
                >
                    {children}
                </Typography>}
        </Box>
    );
};

export default Separator;
