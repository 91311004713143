import {DialogTitle, IconButton} from "@mui/material";
import {X} from "react-feather";
import React from "react";

export default  (props) => {
    const { children, handleOpen, ...other } = props;
    
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={handleOpen}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <X />
            </IconButton>
        </DialogTitle>
    );
};
