import React, {Suspense, Fragment, lazy} from 'react';
import {Switch, Route} from 'react-router-dom';
import MainLayout from '../components/Layout';
import LoadingScreen from '../components/LoadingScreen';
import routeNames from './routeNames';

export const renderRoutes = (routes) => (

    <Suspense fallback={<LoadingScreen/>}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <Guard>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <Component {...props} />
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        path: '/',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: routeNames.HOME,
                component: lazy(() => import('../pages/home'))
            },
            {
                exact: true,
                path: [
                    routeNames.REPERTORIES,
                    `${routeNames.REPERTORIES}/:repertory`,
                    `${routeNames.REPERTORIES}/:repertory/chapters/:chapter`,
                    `${routeNames.REPERTORIES}/:repertory/chapters/:chapter/rubrics/:rubric`,
                    `${routeNames.TRANSLATIONS}/repertory/:repertory/rubric/:rubric`,
                    `${routeNames.TRANSLATIONS}/list`
                ],
                component: lazy(() => import('../pages/repertories')),
            },
            {
                exact: true,
                path: [
                    routeNames.MATERIAMEDICA
                ],
                component: lazy(() => import('../pages/materiaMedica'))
            },
            {
                exact: true,
                path: [
                    routeNames.SEARCH
                ],
                component: lazy(() => import('../pages/materiaMedica'))
            },
            {
                exact: true,
                path: [
                    routeNames.PATIENTS,
                    `${routeNames.PATIENTS}/:patientId`,
                ],
                component: lazy(() => import('../pages/patients'))
            },
            {
                exact: true,
                path: [
                    routeNames.REMEDIES
                ],
                component: lazy(() => import('../pages/remedies'))
            },
            {
                exact: true,
                path: [
                    routeNames.AUTHORS
                ],
                component: lazy(() => import('../pages/authors'))
            },
        ]
    }
];

export default routes;
