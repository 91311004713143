import React, {useState} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Collapse, IconButton,
    ListItem, Tooltip, Typography,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {connect} from "react-redux";
import {SET_COLLAPSE} from "../../../redux/config";
import NavIcon from '../../Icon';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    buttonLeaf: {
        color: theme?.palette.text.secondary,
        padding: '8px',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme?.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme?.typography.fontWeightMedium
            }
        }
    },
    active: {
        color: theme?.palette.secondary.main,
        '& $title': {
            fontWeight: theme?.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme?.palette.secondary.main
        }
    }
}));


const NavItem = ({
                     children,
                     className,
                     depth,
                     id,
                     config,
                     href,
                     icon,
                     info: Info,
                     open: openProp,
                     setCollapse,
                     title,
                     ...rest
                 }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    const getNavIcon = () => {
        return <NavIcon
            name={icon.name}
            style={{width: '1.8rem',  height: '1.6rem', color: icon.color}}
        />
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }
    const style = {paddingLeft};
    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={id}
                {...rest}
            >
                <IconButton
                    tabIndex={-1}
                    sx={{
                        color: (theme) => theme.palette.text.secondary,
                        textTransform: 'none',
                        letterSpacing: 0,
                        width: '100%'
                    }}
                    onClick={handleToggle}
                    style={style}
                >
                    {getNavIcon()}
                    <Typography
                        variant={'body1'}
                        sx={{
                            marginRight: 'auto'
                        }}
                    >
                        {title}
                    </Typography>
                    {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={id}
            {...rest}
        >
            <Tooltip title={title} placement={'right'} arrow={true}>
                <IconButton
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    onClick={() => setCollapse({collapsed: true})}
                    id={id}
                    to={href}
                >
                    {getNavIcon()}
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};


export default connect(
    ({config}) => ({config}),
    (dispatch) => ({
        setCollapse: (payload) => dispatch(SET_COLLAPSE(payload)),
    })
)(NavItem);
