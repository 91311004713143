import React, {useEffect, useState} from "react";
import keyAPI from "../../../../services/keyAPI";
import {
    Box, Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import DateFormat from "../../../DateFormat";
import {Copy} from "react-feather";

export default () => {
    const [apiKeys, setApiKeys] = useState([])

    useEffect(() => {
        search();
    }, [])

    const search = async () => {
        const result = await keyAPI.search({size: 1})
        setApiKeys(result.data)
    }

    const handleCreate = async () => {
        await keyAPI.create();
        await search();
    }


    return (
        <>
            { !apiKeys.length &&
                <Button
                    color={'secondary'}
                    variant='contained'
                    onClick={handleCreate}
                >
                    Generate key
                </Button>
            }
            {apiKeys.map(key =>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={key.active}
                                    />}
                                label="Active"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <DateFormat date={key.createdAt}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={key.value}
                            variant={'outlined'}
                            label={'Secret'}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title={'Copy'}>
                                            <IconButton onClick={() => navigator.clipboard.writeText(key.value)}>
                                                <Copy/>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                </Grid>
            )}
        </>
    )
}