export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "目录",
                patients: "患者",
            }
        },
        rubricOptions: {
            take: "选择",
            view: "查看",
            saveAsBookmark: "保存为书签",
            addRemedy: "添加疗法"
        },
        repertory: {
            settings: "目录设置",
            languages: '目录语言',
            mainLanguage: '主要语言'
        },
        search: "搜索",
        settings: "设置",
        lang: "语言",
        save: "保存",
        selectRemedy: "选择疗法",
        languages: '语言',
        description: "描述",
        close: "关闭",
        note: "备注"
    }
}