import React, { useState } from "react";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box, useTheme } from "@mui/material";
import useConstants from "../../hooks/useConstants";

const classes = (theme, app) => ({
    root: {
        backgroundColor: theme?.palette.background.dark,
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingTop: 8,
        [theme?.breakpoints.up("lg")]: {
            paddingLeft: 8,
        },
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
    siteIndicator: {
        position: "fixed",
        /* display: "flex", */
        top: 0,
        left: 0,
        height: "5px",
        width: "100%",
        backgroundColor: app.color,
    },
    topbar: {
        paddingTop: "5px",
    },
});

const Layout = ({ children }) => {

    const {getApp} = useConstants();
    const app = getApp();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const style = classes(useTheme(), app);

    return (
        <>
            {/*<Box sx={style.siteIndicator} data-id="ele-indicator"></Box>*/}
            <Box sx={style.root}>
               
                <TopBar
                    sx={style.topbar}
                    onMobileNavOpen={() => setMobileNavOpen(true)}
                />
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <Box sx={style.wrapper}>
                    <Box sx={style.contentContainer}>
                        <Box sx={style.content}>{children}</Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Layout;
