export const toolbarButtons = {
    moreText: {
        buttons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "fontFamily",
            "fontSize",
            "textColor",
            "backgroundColor",
            "inlineClass",
            "inlineStyle",
            "clearFormatting"
        ]
    },
    moreParagraph: {
        buttons: [
            "alignLeft",
            "alignCenter",
            "formatOLSimple",
            "alignRight",
            "alignJustify",
            "formatOL",
            "formatUL",
            "paragraphFormat",
            "paragraphStyle",
            "lineHeight",
            "outdent",
            "indent",
            "quote"
        ]
    },
    moreRich: {
        buttons: [
            "insertLink",
            "insertImage",
            "insertVideo",
            "insertTable",
            "emoticons",
            "fontAwesome",
            "specialCharacters",
            "embedly",
            "insertFile",
            "insertHR"
        ]
    },
    moreMisc: {
        buttons: [
            "undo",
            "redo",
            "fullscreen",
            "print",
            "getPDF",
            "spellChecker",
            "selectAll",
            "html",
            "help"
        ],
        align: "right",
        buttonsVisible: 2
    }
}

export const pluginsEnabled = [
    "table",
    "spell",
    "quote",
    "save",
    "quickInsert",
    "paragraphFormat",
    "paragraphStyle",
    "help",
    "draggable",
    "align",
    "link",
    "lists",
    "file",
    "image",
    "emoticons",
    "url",
    "video",
    "embedly",
    "colors",
    "entities",
    "inlineClass",
    "inlineStyle",
    "imageTUI"
]