import React, {
    createContext,
    useReducer
} from 'react';
import lookupAPI from "../services/lookupAPI";
import userAPI from "../services/userAPI";

const initialAuthState = {};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LISTLOCALES': {
            const {locales} = action.payload;
            return {
                ...state,
                locales
            };
        }
        case 'LISTEVALUATIONS': {
            const {evaluations} = action.payload;
            return {
                ...state,
                evaluations
            };
        }
        case 'LISTLANGUAGES': {
            const {languages} = action.payload;
            return {
                ...state,
                languages
            };
        }
        case 'LISTLOOKUPS': {
            const {lookups} = action.payload;
            return {
                ...state,
                lookups: {...state.lookups, ...lookups }
            };
        }
        case 'LISTTRANSLATORS': {
            const {translators} = action.payload;
            return {
                ...state,
                translators
            };
        }
        case 'LISTLABELS': {
            const {labels} = action.payload;
            return {
                ...state,
                labels
            };
        }
        default: {
            return {...state};
        }
    }
};

const ConstantsContext = createContext({
    ...initialAuthState
});

export const ConstantsProvider = ({children}) => {

    const apps = { 
        'CC' : {
            'id': 'CC',
            'name' : 'OpusPlus',
            'color' : '#e025a9',
        },
        'TT' : {
            'id': 'TT',
            'name' : 'Translation Tools',
            'color' : '#a9e025',
        }
    }

    //console.log("apps", apps);

    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const getApp = () => { 
        return window.location.hostname.includes("translation") ? apps['TT'] : apps['CC'];
    };

    const listLocales = async () => {
        const result = await lookupAPI.listLocales();
        dispatch({
            type: 'LISTLOCALES',
            payload: {
                locales: result.data
            }
        });
    };

    const listEvaluations = async () => {
        const result = await lookupAPI.listEvaluations();
        dispatch({
            type: 'LISTEVALUATIONS',
            payload: {
                evaluations: result.data
            }
        });
    };

    const listLanguages = async () => {
        const result = await lookupAPI.listLanguages();
        dispatch({
            type: 'LISTLANGUAGES',
            payload: {
                languages: result.data
            }
        });
    };

    const listLabels = async () => {
        const result = await lookupAPI.listLabels();
        dispatch({
            type: 'LISTLABELS',
            payload: {
                labels: result.data
            }
        });
    };

    const listLookups = async ({kinds}) => {
        const result = await lookupAPI.listLookups({lookupTypeIds: kinds.toString()});
        kinds.map( kind => {
            dispatch({
                type: 'LISTLOOKUPS',
                payload: {
                    lookups: {[kind]: result.data?.filter( i => i.kind == kind)}
                }
            });
        })

    };

    const listTranslators = async () => {
        try {
            const result = await userAPI.listTranslators();
            dispatch({
                type: 'LISTTRANSLATORS',
                payload: {
                    translators: result.data
                }
            });
        } catch (e) {
            dispatch({
                type: 'LISTTRANSLATORS',
                payload: {
                    translators: null
                }
            });
        }
       
    };
    
    return (
        <ConstantsContext.Provider
            value={{
                ...state,
                listLanguages,
                listLocales,
                listLookups,
                listEvaluations,
                listTranslators,
                listLabels,
                getApp
            }}
        >
            {children}
        </ConstantsContext.Provider>
    );
};

export default ConstantsContext;
