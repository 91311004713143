import React, {useEffect, useState} from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {TreeItem, TreeView} from "@mui/lab";
import noteDirectoryAPI from "../../../services/noteDirectoryAPI";
import {useSnackbar} from "notistack";
import Loader from "../../Loader";
import DirectoryActions from "./Directory/Actions";
import NoteActions from "./Note/Actions";
import {useQuery} from "react-query";
import {Grid, IconButton} from "@mui/material";
import {Plus} from "react-feather";


export default ({open, handleGetNote, selectedNote, updateNoteIfOpen}) => {
    const [directories, setDirectories] = useState([]);
    const {enqueueSnackbar} = useSnackbar();


    const fetchDirectories = async () => {
        if (!open) return;
        const result = await noteDirectoryAPI.list();
        setDirectories(result.data)
    }

    const {isFetching, isError} = useQuery(['directories', open], fetchDirectories, {
        refetchOnWindowFocus: false
    });

    const handleSaveNote = async (e, note) => {
        e.stopPropagation();
        await noteDirectoryAPI.updateNote(note);
        updateNoteIfOpen(note.id)
    }

    const handleAddFolder = async (e, parentId) => {
        e.stopPropagation();
        return await noteDirectoryAPI.create({
            name: 'new folder',
            parentId: parentId
        })
    }

    const addFolder = async (e) => {
        const {data} = await handleAddFolder(e)
        setDirectories([...directories, data])
    }

    if (isError) {
        enqueueSnackbar('Directories could not be loaded', {
            variant: 'error'
        });    }



    const renderTree = (directory) => (
        <>
            <TreeItem
                key={directory.id}
                nodeId={directory.id.toString()}
                label={
                    <DirectoryActions
                        directory={directory}
                        handleAddFolder={handleAddFolder}
                        directories={directories}
                        setDirectories={setDirectories}
                        loadDirectories={fetchDirectories}
                    />
                }
            >
                {directory.notes?.map(i =>
                    <TreeItem
                        key={i.id}
                        nodeId={i.id.toString()}
                        sx={{backgroundColor: (theme) => selectedNote?.id == i.id && theme.palette.action.selected}}
                        label={
                            <NoteActions
                                note={i}
                                handleSave={handleSaveNote}
                                handleGetNote={handleGetNote}
                                directories={directories}
                                setDirectories={setDirectories}
                            />}
                    />
                )}

                {directory.children?.map((node) => renderTree(node))}
            </TreeItem>

        </>
    );

    return (
        <>
            <Grid container justifyContent={'flex-end'}>
                <IconButton onClick={addFolder}>
                        <Plus/>
                </IconButton>
            </Grid>
            {
                isFetching ? <Loader/> :
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        sx={{flexGrow: 1, overflowY: 'auto'}}
                    >
                        {directories.map(d => renderTree(d))}
                    </TreeView>
            }
        </>
    )
}

