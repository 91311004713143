import metadata from '../scripts/metadata.json';

export const APP_VERSION = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;
export const BUILD_REVISION = metadata.buildRevision;

export const LOOKUP_TYPES = {
    gender: 'AN_SEX',
    bloodGroup: 'PE_BLOOD',
    contactType: 'CT_TYPE',
    scale: 'MA_DILSC',
    state: 'PA_STATE',
    region: 'PA_REGION',
    nationality: 'PE_NATIO',
    archiveLocation: 'AN_LOCAT',
    title: 'AN_QUALI',
    laboratory: 'MA_LABO',
    potency: 'MA_DINAM',
    posology: 'MA_POSOL',
    group: 'GP',
    trend: 'DI_TREND',
    duration: 'DURATION'
}

export const TABINFOS = [
    {
        id: 'selectedClipboard',
        tab: 'clipboardTab'
    },
    {
        id: 'selectedRemedy',
        tab: 'remedyTab'
    },
    {
        id: 'newPatient',
        tab: 'newPatientTab'
    },
    {
        id: 'selectedPatient',
        tab: 'patientTab'
    },
    {
        id: 'newPatient',
        tab: 'newPatientTab'
    },
    {
        id: 'selectedTranslation',
        tab: 'translationTab'
    },
    {
        id: 'selectedConsultation',
        tab: 'consultationTab'
    },
    {
        id: 'allTranslations',
        tab: 'allTranslationsTab'
    }
]
