import * as React from "react";
import {useEffect, useRef, useState} from "react";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import Froala from "react-froala-wysiwyg";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";
import useOptions from "./options";
import {pluginsEnabled, toolbarButtons} from "./config";
import {useTheme} from "@mui/material";
import './styles.scss'

export default ({uploadFile, handleContentChange, model, setModel}) => {
    const ref = useRef({editor: null});
    const theme = useTheme();

    const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
    const options = useOptions();
    const tribute = new Tribute(options);
    const [editor, setEditor] = useState(undefined);


    const handleModelChange = (model) => {
        handleContentChange && handleContentChange(model)
        setModel(model);
    };

    const keys = {
        ENTER: 13,
        SLASH: 55
    }

    // Editor initialization
    useEffect(() => {
        setEditor(ref.current.editor);
        editor && setIsFroalaInitialized(true);
    }, [ref.current]);


    // Do after initialization
    useEffect(() => {
        if (!isFroalaInitialized) return;
        if ( !editor.events || !editor.html )  return;
        tribute.attach(editor.el);
        editor.html.set(model);

        editor.events.on('keydown', (e) => {

            if (e.which == keys.ENTER && tribute.isActive) {
                return false;
            }
        }, true);
    }, [isFroalaInitialized]);


    const events = {
        'image.beforeUpload': async (files) => {
            await uploadFile(files[0])
        },
        'file.beforeUpload': async (files) => {
            await uploadFile(files[0])
        },
    }

    return (
        <div
            className={theme.palette.type == 'dark' ? 'eg-dark-theme' : ''}
        >
            <Froala
                ref={ref}
                model={model}
                onModelChange={handleModelChange}
                tag="textarea"
                config={{
                    attribution: false,
                    placeholder: "Start typing...",
                    events,
                    toolbarButtons,
                    pluginsEnabled
                }}
            />

        </div>
    );
};