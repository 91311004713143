import React, {useState} from 'react';
import {
    Box,
    Dialog, DialogContent, Grid,
    IconButton, Tooltip, Typography,
} from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import MyDialogTitle from "../DialogTitle";
import NoteTable from "./NoteTable";
import FroalaNoteTaker from "../FroalaNoteTaker";
import noteDirectoryAPI from "../../services/noteDirectoryAPI";

import Media from "./Media";
import fileAPI from "../../services/fileAPI";
import LoadingScreen from "../LoadingScreen";
import useFormatDate from "../../hooks/useFormatDate";
import {useQuery} from "react-query";

const Notes = () => {
    const [open, setOpen] = useState(false);
    const [noteId, setNoteId] = useState(null);
    const [files, setFiles] = useState([]);

    const fetchNote = async () => {
        if (!noteId) return;
        loadFiles(noteId);
        return await noteDirectoryAPI.getNote(noteId);
    }

    const {isFetching, data: noteResult, refetch} = useQuery(['note', noteId], fetchNote, {
        refetchOnWindowFocus: false
    });

    const note = noteResult?.data;
    const date = useFormatDate(note?.createdAt)

    const handleOpen = () => {
        setOpen(!open);
    };

    const updateNoteIfOpen = (updatedId) => {
        updatedId === note.id && refetch()
    }


    const loadFiles = async (id) => {
        const result = await fileAPI.getNoteFiles(id);
        setFiles(result.data)
    }
    return (
        <>
            <Tooltip title={'Notes'} placement={'right'} arrow={true}>
                <IconButton
                    onClick={handleOpen}
                >
                    <StickyNote2Icon/>
                </IconButton>
            </Tooltip>
            {open && <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        height: 800
                    }
                }}
                fullWidth
                maxWidth={'xl'}
                keepMounted
            >
                <MyDialogTitle handleOpen={handleOpen}>
                    Your notes
                </MyDialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <NoteTable
                                selectedNote={note}
                                updateNoteIfOpen={updateNoteIfOpen}
                                handleGetNote={(id) => setNoteId(id)}
                                open={open}
                            />
                        </Grid>
                        {!isFetching ? <>
                            {note &&
                                <Grid item xs={9} sx={{borderLeft: '2px solid #eaeaea'}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Typography sx={{whiteSpace: 'noWrap'}} variant={'h4'}>
                                                    {note.name}
                                                </Typography>
                                                <Grid container justifyContent={'flex-end'}>
                                                    {date}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid mt={2} item xs={8}>
                                            <FroalaNoteTaker
                                                loadFiles={loadFiles}
                                                note={note}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Media
                                                files={files}
                                                setFiles={setFiles}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </> : <Grid item>
                            <LoadingScreen/>
                        </Grid>}
                    </Grid>

                </DialogContent>
            </Dialog>}
        </>
    )
        ;
};

export default Notes;
