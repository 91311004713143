import noteDirectoryAPI from "../../../../services/noteDirectoryAPI";
import {Box, Grid, IconButton, TextField, Typography} from "@mui/material";
import {Check, Delete, Edit} from "@mui/icons-material";
import React, {useState} from "react";
import AddMenu from "./AddMenu";
import {X} from "react-feather";
//import MailIcon from '@mui/icons-material/Mail';
import FolderIcon from '@mui/icons-material/Folder';

export default ({directory, directories, handleAddFolder, setDirectories, loadDirectories}) => {
    const [update, setUpdate] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleAdd = (type, data) => {
        if (directory[type]) {
            directory[type].unshift(data);
        } else {
            directory[type] = [data]
        }
        setDirectories([...directories])
    }

    const handleAddNote = async (e) => {
        e.stopPropagation();
        const result = await noteDirectoryAPI.createNote({
            name: 'new note',
            directoryId: directory.id
        })
        handleAdd('notes', result.data)

    }

    const handleSave = async () => {
        await noteDirectoryAPI.update(directory)
        setUpdate(false);
    }


    const handleDelete = async () => {
        await noteDirectoryAPI.deleteDir(directory.id);
        loadDirectories();
    }

    const handleUpdate = (e) => {
        e.stopPropagation();
        setUpdate(!update)
    }

    const handleEdit = e => {
        e.stopPropagation();
        directory.name = e.target.value
        setDirectories([...directories])
    }

    return (
        <Grid
            container
            alignItems={'center'}
            sx={{minHeight: '3rem'}}
            justifyContent={'space-between'}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            <Grid item xs={6}>
                {update ?
                    <TextField
                        variant={'outlined'}
                        value={directory.name}
                        onChange={handleEdit}/>
                    :
                    <Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
                        <Box component={FolderIcon} color="inherit" sx={{mr: 1}}/>
                        <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
                            {directory.name}
                        </Typography>

                    </Box>
                }
            </Grid>
            {visible &&
                <>
                    {update ?
                        <>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={handleSave}
                                >
                                    <Check/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={handleUpdate}
                                >
                                    <X/>
                                </IconButton>
                            </Grid>

                        </>
                        :
                        <>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={handleUpdate}
                                >
                                    <Edit/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2}>
                                <AddMenu
                                    handleAddFolder={async (e) => {
                                        const result = await handleAddFolder(e, directory.id)
                                        handleAdd('children', result.data)
                                    }}
                                    handleAddNote={handleAddNote}/>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={handleDelete}>
                                    <Delete/>
                                </IconButton>
                            </Grid>
                        </>
                    }

                </>
            }
        </Grid>
    )
}