import { serviceInterface, getDefParams } from "./config";



const listPersonalDataFields = () => {
    return serviceInterface.get(`/fields/personal-data`)
}

const listFreeFields = () => {
    return serviceInterface.get(`/fields/free-fields`)
}

const listPatientPersonalDataFields = (patientId) => {
    return serviceInterface.get(`patient-fields/${patientId}/personal-data`)
}

const listPatientFreeFields = (patientId) => {
    return serviceInterface.get(`patient-fields/${patientId}/free-fields`)
}


const saveData = (patientId, values) => {
    return serviceInterface.post(`patient-fields/${patientId}`, values)
}



const updateFreeFields = ( values) => {
    return serviceInterface.post(`fields/free-fields`, values)
}




export default {
    listPersonalDataFields,
    listPatientFreeFields,
    updateFreeFields,
    saveData,
    listFreeFields,
    listPatientPersonalDataFields
};
