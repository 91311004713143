import React, {useRef, useState} from 'react';
import {
    Box, Button,
    Collapse,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useDrag, useDrop} from "react-dnd";
import {X} from "react-feather";
import {connect} from "react-redux";
import {GET_REPERTORY} from "../../redux/repertories";
import {SET_SELECTED_CHAPTER} from "../../redux/tabs";
import {CLEAR_RUBRICS, GET_RUBRICS} from "../../redux/rubrics";


const BookmarkTable = ({
                           getRepertory,
                           setSelectedChapter,
                           clearRubrics,
                           getRubrics,
                           categories,
                           setCategories,
                           handleOpen,
                           deleteBookmark
                       }) => {
    
    const viewRepertory = (rubric) => {
        handleOpen();
        getRepertory({documentId: rubric.documentId, chapterId: rubric.chapterId})
        clearRubrics({
            documentId: rubric.documentId,
            chapterId: rubric.chapterId
        });
        setSelectedChapter({id: rubric.documentId, chapter: rubric.chapterId});
        getRubrics({
            includeRubricId: true,
            documentId: rubric.documentId,
            chapterId: rubric.chapterId,
            rubricId: rubric.id,
            direction: 'next',
            size: 20
        });
    }
    
    const BookmarkRow = ({bookmark}) => {
        
        const [{isDragging}, drag] = useDrag({
            type: 'bookmarkRow',
            item: () => {
                return bookmark
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })
        return (
            <TableRow ref={drag}
                      key={bookmark.id}>
                <TableCell>
                    <Button onClick={() => viewRepertory(bookmark.rubric)}>
                        
                        {bookmark.name}
                    </Button>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => deleteBookmark(bookmark.id)}>
                        <X/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    
    
    const Row = ({row}) => {
        const [open, setOpen] = useState(true);
        
        const [{handlerId, isOverCurrent}, drop] = useDrop({
            accept: `bookmarkRow`,
            collect: monitor => ({
                hovered: monitor.isOver(),
                canDrop: monitor.canDrop(),
                isOverCurrent: monitor.isOver({shallow: true})
            }),
            drop(item, monitor) {
                setCategories(categories.map(category => {
                    if (category.id === row.id) {
                        item.categoryId = row.id;
                        category.bookmarks.push(item)
                    }
                    return category
                }))
            },
        })
        
        return (
            <>
                <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell ref={drop}
                               sx={(theme) => ({backgroundColor: theme.palette.action.selected})}

                    >
                        <Grid container>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {row.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Table size="small">
                                <TableBody>
                                    {row.bookmarks.map((bookmark) => (
                                        <BookmarkRow key={bookmark.id} bookmark={bookmark}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableRow>
            </>
        );
    }
    
    return (
            <Table >
                <TableBody>
                    {categories.map(category => <Row
                        key={category.id}
                        row={category}
                    />)}
                </TableBody>
            </Table>

    )
}

export default connect(
    ({}) => ({}),
    (dispatch) => ({
        getRepertory: (payload) => dispatch(GET_REPERTORY(payload)),
        setSelectedChapter: (payload) => dispatch(SET_SELECTED_CHAPTER(payload)),
        getRubrics: (payload) => dispatch(GET_RUBRICS(payload)),
        clearRubrics: (payload) => dispatch(CLEAR_RUBRICS(payload))
    })
)(BookmarkTable);
