import React, {
    createContext,
    useReducer
} from 'react';

import {getSettings, setSettings} from "../utils";

const initialAuthState = {
    theme: getSettings()?.theme,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'HANDLEOPEN': {
            const {open, tab} = action.payload;
            return {
                ...state,
                open,
                tab
            };
        }
        case 'HANDLETAB': {
            const { tab} = action.payload;
            return {
                ...state,
                tab
            };
        }
        case 'HANDLETHEMECHANGE': {
            const { theme} = action.payload;
            return {
                ...state,
                theme
            };
        }


        default: {
            return {...state};
        }
    }
};

const SettingsContext = createContext({
    ...initialAuthState,
    open: false
});

export const SettingsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const handleOpen = (open, tab = '1') => {
        dispatch({
            type: 'HANDLEOPEN',
            payload: {
                open,
                tab
            }
        });
    };

    const handleThemeChange = (theme) => {
        setSettings('theme', theme);

        dispatch({
            type: 'HANDLETHEMECHANGE',
            payload: {
                theme
            }
        });
    };

    const handleTab = ( tab = '1') => {
        dispatch({
            type: 'HANDLETAB',
            payload: {
                tab
            }
        });
    };


    return (
        <SettingsContext.Provider
            value={{
                ...state,
                handleOpen,
                handleThemeChange,
                handleTab
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;
