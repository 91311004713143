import { apis,  serviceInterface } from "./config";

const search = (params) => {
    return serviceInterface.get('/remedies', {
        params: {  ...params },
    });
};

const get = (id) => {
    return serviceInterface.get(`/remedies/${id}`);
};

export default {
    search,
    get
};
