import { serviceInterface, getDefParams } from "./config";

const search = (params) => {
    return serviceInterface.get(`/patients`, {
        params: {...getDefParams(), ...params},
    });
};

const get = (id) => {
    return serviceInterface.get(`/patients/${id}`, {
        params: {...getDefParams()}
    });
};



const create = ( body) => {
    return serviceInterface.post(`/patient`, body, {
        params: {...getDefParams()}
    
    });
};
const modify = ( body) => {
    return serviceInterface.put(`/patient`, body, {
        params: {...getDefParams()}
    
    });
};
const updateUserfields = ( id, body) => {
    return serviceInterface.put(`/patients/fields/${id}`, body, {
        params: {...getDefParams()}

    });
};


export default {
    get,
    search,
    create,
    modify,
    updateUserfields
};
