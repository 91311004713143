import { apis, serviceInterface } from "./config";

const search = (params) => {
    return serviceInterface.get(apis.GET_SYMPTOMS, {
        params: params,
    });
};

const get = (params) => {
    return serviceInterface.get("/rubric/get", {
        params: params,
    });
};

const addRemedy = (rubricId, body) => {
    return serviceInterface.post(`rubric/${rubricId}/remedy`, body);
};

const getParents = (params) => {
    return serviceInterface.get(apis.GET_PARENTS, {
        params: params,
    });
};

const slide = (params) => {
    return serviceInterface.get(apis.SLIDE_SYMPTOMS, {
        params: params,
    });
};

export default {
    search,
    get,
    slide,
    addRemedy,
    getParents,
};
