export default {
    translation: {
        tableOfContent: {
            titles: {
                repertories: "Repertori",
                patients: "Pazienti",
            }
        },
        rubricOptions: {
            take: "Prendi",
            view: "Visualizza",
            saveAsBookmark: "Salva come segnalibro",
            addRemedy: "Aggiungi Rimedio"
        },
        repertory: {
            settings: "Impostazioni Repertorio",
            languages: 'Lingue del repertorio',
            mainLanguage: 'Lingua principale'
        },
        search: "Cerca",
        settings: "Impostazioni",
        lang: "Lingua",
        save: "Salva",
        selectRemedy: "Seleziona rimedio",
        languages: 'Lingue',
        description: "Descrizione",
        close: "Chiudi",
        note: "Note"
    }
}