import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
//import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Divider,
    Drawer,
    List,
    Button,
    ListSubheader,
} from '@mui/material';
import Logo from '../Logo';
import sections from './sections';
import renderNavItems from './renderNavItems'
import Search from "../../Search";
import {BUILD_REVISION} from "../../../constants";
import TranslateCommand from "../../TranslateCommand"
import {connect} from "react-redux";
import {OPEN_TRANSLATION_TAB} from "../../../redux/tabs";
import {API} from '../../../config'
import Bookmarks from "../../Bookmarks";
import Notes from "../../Notes";
import useConstants from "../../../hooks/useConstants";

const NavBar = ({onMobileClose, openMobile, openTranslationTab}) => {
    const location = useLocation();
    const {getApp} = useConstants();
    const app = getApp();

    //console.log("NavBar:showTranslation", showTranslation);
    //console.log("NavBar:fakeFunc", fakeFunc());

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <PerfectScrollbar>
            <Box sx={{padding: '.6rem'}} display='flex' justifyContent='center'>
                <Logo/>
            </Box>
            <Divider/>
            <Box p={2}>
                <Search/>
            </Box>
            <Divider/>
            <Box p={2}>
                {sections.map((section, index) => (
                    <List
                        key={index}
                        subheader={
                            <ListSubheader disableGutters disableSticky>
                                {section.subheader}
                            </ListSubheader>
                        }
                    >
                        {
                            renderNavItems({
                                items: section.items,
                                pathname: location.pathname
                            })
                        }
                    </List>
                ))}
            </Box>
            <Divider/>
            {/*<Button onClick={fakeFunc} data-fake />*/}
            <Box p={2}>
                <Bookmarks/>
                <Notes/>
                <TranslateCommand onClick={openTranslationTab}/>
            </Box>
            <Divider/>
            <Box display='flex' ml={2}>
                <a style={{color: 'black', fontSize: '16px'}} target='_blank' href={`${API.url}/docs/change-log`}>
                    V. {BUILD_REVISION}
                </a>
            </Box>
        </PerfectScrollbar>
    );

    return (
        <>
            <Box
                sx={{display: {lg: 'none', xs: 'block'}}}
            >
                <Drawer
                    anchor='left'
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 80
                        }
                    }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant='temporary'
                >
                    {content}
                </Drawer>
            </Box>
            <Box sx={{display: {lg: 'block', xs: 'none'}}}>
                <Drawer
                    anchor='left'
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 70,
                            height: '100%',
                            boxShadow: theme => `10px 0 20px -1px ${theme.boxShadow}`,
                            overflowX: 'hidden'
                        }
                    }}
                    open
                    variant='persistent'
                >
                    {content}
                </Drawer>
            </Box>
        </>
    );
};


export default connect(
    ({repertorySettings}) => ({repertorySettings}),
    (dispatch) => ({
        // fakeFunc: () => console.log('console:fakeFunc'), 
        openTranslationTab: (payload) => dispatch(OPEN_TRANSLATION_TAB(payload)),
    })
)(NavBar);
