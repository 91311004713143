import React from 'react';
import {ReactSVG} from 'react-svg'
import MySVG from './logo-opusplus.svg';
import {useTheme} from "@mui/material";

const Logo = () => {
    const theme = useTheme();

    return (
            <ReactSVG
                src={MySVG}
                beforeInjection={(svg) => {
                    svg.setAttribute('style', `width: 50px; height: 40px; fill: ${theme.palette.primary.main};`)
                }}
            />
    );
};

export default Logo;