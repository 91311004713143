import noteDirectoryAPI from "../../../../services/noteDirectoryAPI";
import {Grid, IconButton, TextField} from "@mui/material";
import {Check, Delete, Edit, ViewAgenda} from "@mui/icons-material";
import React, {useState} from "react";

export default ({note, directories, setDirectories, handleSave, handleGetNote}) => {
    const [update, setUpdate] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleDelete = async () => {
        await noteDirectoryAPI.deleteNote(note.id);
    }

    const handleUpdate = async (e) => {
        e.stopPropagation();
        setUpdate(!update)
    }

    const handleEdit = e => {
        e.stopPropagation();
        note.name = e.target.value
        setDirectories([...directories])
    }

    return (
        <Grid
            container
            alignItems={'center'}
            sx={{minHeight: '3rem'}}
            justifyContent={'space-between'}
            onClick={() => handleGetNote(note.id)}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            <Grid item xs={8}>
                {update ?
                    <TextField
                        variant={'outlined'}
                        value={note.name}
                        onChange={handleEdit}/>
                    :
                    <>
                        {note.name}
                    </>}
            </Grid>

            {visible &&
                <>
                    < Grid item xs={2}>
                        {update ?
                            <IconButton
                                onClick={(e) => {
                                    handleSave(e, note);
                                    setUpdate(!update)

                                }}
                            >
                                <Check/>
                            </IconButton>
                            :
                            <IconButton
                                onClick={handleUpdate}
                            >
                                <Edit/>
                            </IconButton>}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleDelete}>
                            <Delete/>
                        </IconButton>
                    </Grid>
                </>
            }
        </Grid>
    )
}